/* @refresh reload */

import { customElement, noShadowDOM } from 'solid-element'
import { Component, createEffect, createSignal, Match, Show, Switch } from 'solid-js'

type Routes = 'trip' | 'stopTimetable' | 'departureMonitor'
type Languages = 'en' | 'de'

interface Props {
	'page-url': string
}

const routeNameTranslations: { [r in Routes]: { [l in Languages]: string } } = {
	trip: {
		en: 'Journey planner',
		de: 'Fahrtauskunft',
	},
	stopTimetable: {
		en: 'Stop timetable',
		de: 'Haltestellenfahrplan',
	},
	departureMonitor: {
		en: 'Departure monitor',
		de: 'Abfahrten',
	},
}

const NavigationEfa: Component<Props> = props => {
	noShadowDOM()

	const [selectedRoute, setSelectedRoute] = createSignal<Routes>('trip')
	const [openLanguageSelect, setOpenLanguageSelect] = createSignal(false)
	const [selectedLanguage, setSelectedLanguage] = createSignal<Languages>('de')

	if (window.location.href.includes('trip')) {
		setSelectedRoute('trip')
	} else if (window.location.href.includes('departureMonitor')) {
		setSelectedRoute('departureMonitor')
	} else if (window.location.href.includes('stopTimetable')) {
		setSelectedRoute('stopTimetable')
	}

	if (window.location.href.includes('lng=en')) {
		setSelectedLanguage('en')
	} else if (window.location.href.includes('lng=de')) {
		setSelectedLanguage('de')
	}

	return (
		<nav class="efa-sl3p-tabnav js-efa-sl3p-tabnav" aria-labelledby="sl3p-tabnav-heading">
			<div class="scrollcontainer">
				<h2 id="sl3p-tabnav-heading" class="visually-hidden">
					EFA-Menü
				</h2>
				<ul role="menubar">
					<li role="menuitem" class="language-select" onClick={() => setOpenLanguageSelect(!openLanguageSelect())}>
						<div class="img-wrapper">
							<Switch>
								<Match when={selectedLanguage() === 'en'}>
									<img
										src="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Flags/Flagge-Englisch.png"
										alt="flag gb"
										class="flag gb"
									/>
								</Match>
								<Match when={selectedLanguage() === 'de'}>
									<img
										src="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Flags/Flagge-Deutschland.png"
										alt="flag de"
										class="flag de"
									/>
								</Match>
							</Switch>
						</div>
						<Show when={openLanguageSelect()}>
							<ul class="language-options">
								<li>
									<a
										class="efa-sl3p-tabnav-item"
										href={`${props['page-url']}?lng=de`}
										data-applinktype="de"
										aria-current={selectedLanguage() === 'de'}>
										<div class="radio" />
										<span>Deutsch</span>
									</a>
								</li>
								<li>
									<a
										class="efa-sl3p-tabnav-item"
										href={`${props['page-url']}?lng=en`}
										data-applinktype="en"
										aria-current={selectedLanguage() === 'en'}>
										<div class="radio" />
										<span>English</span>
									</a>
								</li>
							</ul>
						</Show>
					</li>
					<li role="menuitem">
						<a
							class="efa-sl3p-tabnav-item"
							href={`${props['page-url']}/trip?lng=${selectedLanguage()}`}
							aria-current={selectedRoute() === 'trip'}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								height="24"
								width="24"
								viewBox="0 0 24 24"
								aria-hidden="true"
								style={{ display: 'flex' }}>
								<path d="M0 0h24v24H0V0z" fill="none" />
								<path d="M12 7.27l4.28 10.43-3.47-1.53-.81-.36-.81.36-3.47 1.53L12 7.27M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71L12 2z" />
							</svg>
							<span>{routeNameTranslations['trip'][selectedLanguage()]}</span>
						</a>
					</li>
					<li role="menuitem">
						<a
							class="efa-sl3p-tabnav-item"
							href={`${props['page-url']}/departureMonitor?lng=${selectedLanguage()}`}
							aria-current={selectedRoute() === 'departureMonitor'}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								height="24"
								width="24"
								viewBox="0 0 24 24"
								aria-hidden="true"
								style={{ display: 'flex' }}>
								<path d="M0 0h24v24H0V0z" fill="none" />
								<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
							</svg>
							<span>{routeNameTranslations['departureMonitor'][selectedLanguage()]}</span>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	)
}

customElement('navigation-efa', { 'page-url': '' }, NavigationEfa)
